import styled from 'styled-components'
import { Tabs } from 'antd'

export const StyledTabs = styled(Tabs)`
  max-width: 466px;
  margin: auto;

  @media (max-width: 500px) {
    padding: 0 24px;
  }

  @media (max-width: 400px) {
    max-width: 100vw;
  }

  & .ant-tabs-nav:before {
    border-bottom: unset;
  }

  & .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
    @media (max-width: 400px) {
      font-size: 18px;
    }
  }

  & .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 600;
    line-height: 32px;
    color: #43043b;
    opacity: 0.4;

    & :hover {
      opacity: 1;
    }
  }

  & .ant-tabs-nav {
    margin-bottom: 31px;
    margin-top: 40px;
  }

  & .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
  }

  & .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:nth-child(2) {
    margin-right: 0;
  }

  & .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    opacity: 1;
    text-decoration: underline;
  }

  & .ant-tabs-ink-bar {
    display: none;
  }
`
