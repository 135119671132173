import Link from 'next/link'
import { Input, Form, Modal, Button } from 'antd'
import { signIn, useSession } from 'next-auth/client'
import {
  StyledSpace,
  StyledButton,
  StyledLink,
  StyledFormItem,
  StyledDivider,
  StyledLinkAccount,
  StyledText,
  StyledSpaceSocialLogins,
  StyledBtnFacebook,
  StyledBtnGoogle,
} from './styles'
import { FacebookFilled, GoogleOutlined } from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import api from '@/services/api'
import { AutoDestroyModal } from '@/components/shared/AutoDestroyModal'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/nextjs'
import { useClient } from '@/contexts/ClientContext'

type LoginProps = {
  email: string
  password: string
}

interface LoginFormProps {
  handleTabsCliced: (key: string) => void
}

const LoginForm = ({ handleTabsCliced }: LoginFormProps) => {
  const router = useRouter()
  const [session] = useSession()
  const [form] = useForm()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (router.isReady) {
      const { email, key, newsletter } = router.query

      if (email && email != '') {
        form.setFields([{ name: 'email', value: email }])
        Cookies.set('clientEmail', email, { expires: 60 })
      }

      if (key && key != '') {
        try {
          api.post('/customers/active-account', { email, key, newsletter }).then(() => {
            AutoDestroyModal({
              type: 'success',
              title: 'Conta ativada com sucesso',
            })
            router.replace('/cliente/entrar')
          })
        } catch (error) {
          Sentry.captureException(error)
        }
      }
    }
  }, [form, router])

  const onSubmit = async (data: LoginProps) => {
    //console.log(data)
    setIsLoading(true)
    Cookies.set('clientEmail', data.email, { expires: 60 })
    try {
      await api.post('customers/login', {
        email: data.email,
        password: data.password,
      })
    } catch (error) {
      setIsLoading(false)
      if (error.response.data.code && error.response.data.code == '001') {
        Modal.info({
          title: 'Conta por ativar',
          onOk() {
            api.post('/customers/active-account', { email: data.email }).then(() => {
              AutoDestroyModal({
                type: 'success',
                title: 'Confirmação conta necessária',
                content: 'Foi enviado um email com o link para ativares a tua conta',
              })
            })
          },
          okButtonProps: { type: 'primary' },
          okText: 'Reenviar email',
          cancelText: 'Cancelar',
          closable: true,
        })
        return
      } else {
        Sentry.captureException(error)
      }
    }
    Cookies.set('logout', '5')
    const response = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    })
    if (response) {
      if (response.error === 'AccessDenied') {
        form.setFields([
          {
            name: 'password',
            errors: ['Email e/ou password inválidos'],
          },
        ])
      }
      setIsLoading(false)
    }
    window.fbq('track', 'SubmitApplication', {
      content_name: 'login',
    })
    setIsLoading(false)
  }

  return (
    <StyledSpace direction="vertical" size={0}>
      <Form layout="vertical" size="large" onFinish={onSubmit} method="POST" form={form}>
        <StyledFormItem label="Endereço de email" name="email">
          <Input placeholder="Email" type="email" />
        </StyledFormItem>
        <StyledFormItem label="Palavra-passe" name="password">
          <Input placeholder="Palavra-passe" type="password" />
        </StyledFormItem>
        <Link href="/cliente/recuperar-palavra-passe">
          <StyledLink> Esqueceu-se da palavra-passe? </StyledLink>
        </Link>
        <Form.Item>
          <StyledButton loading={isLoading} type="primary" size="middle" htmlType="submit">
            {isLoading ? 'A entrar...' : 'Entrar'}
          </StyledButton>
        </Form.Item>
      </Form>
      <StyledDivider>ou</StyledDivider>
      <StyledSpaceSocialLogins direction="vertical" size={13}>
        {!session && (
          <>
            <StyledBtnGoogle onClick={() => signIn('google')}>
              <GoogleOutlined />
              Continuar com Google
            </StyledBtnGoogle>
            <StyledBtnFacebook onClick={() => signIn('facebook')}>
              <FacebookFilled />
              Continuar com facebook
            </StyledBtnFacebook>
          </>
        )}
        {session && <>{session.user.email}</>}
      </StyledSpaceSocialLogins>
      <StyledText>
        Ainda não tem conta?
        <StyledLinkAccount onClick={() => handleTabsCliced('2')}>
          Criar nova conta
        </StyledLinkAccount>
      </StyledText>
    </StyledSpace>
  )
}

export default LoginForm
