import styled from 'styled-components'
import { Space, Button, Form, Divider } from 'antd'
import Text from 'antd/lib/typography/Text'

export const StyledSpace = styled(Space)`
  width: 100%;
  display: flex;
  padding-bottom: 32px;
`
export const StyledButton = styled(Button)`
  width: 100%;
`
export const StyledLink = styled.a`
  margin-top: -4px;
  margin-bottom: 32px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-family: Lato;
  font-weight: 400;
`
export const StyledFormItem = styled(Form.Item)`
  & .ant-form-item-label label {
    font-family: Lato;
  }

  & .ant-form-item-control input {
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
  }

  & .ant-form-item-control .ant-picker {
    width: 100%;
    height: 48px;
  }

  .ant-form-item-control input {
    width: 100%;
    color: #393939;
  }
`
export const StyledDivider = styled(Divider)`
  margin: 44px 0 51px !important;
  color: #393939 !important;
  font-size: 16px !important;
  line-height: 24px;
  font-family: 'Lato';
`
export const StyledLinkAccount = styled.a`
  font-size: 14px;
  line-height: 24px;
  font-family: Lato;
  font-weight: 400;
  padding-left: 10px;
`
export const StyledText = styled(Text)`
  font-size: 14px;
  line-height: 24px;
  font-family: Lato;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
`
export const StyledSpaceAgreeTerms = styled(Space)`
  width: 100%;
  margin-bottom: 80px;
  margin-top: -4px;
  display: flex;
`
