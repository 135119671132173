declare let jic: any

interface SmarkioVariablesProps {
  key: string
  name: string
  value: string
}

interface SmarkioGetVariablesReturn {
  [key: string]: any
}

const SmarkioGetVariables = (): SmarkioGetVariablesReturn[] => {
  const smarkIoVariables: SmarkioVariablesProps[] =
    typeof jic !== 'undefined' ? jic.variables() : []

  const smarkIoVariablesKeyValue: SmarkioGetVariablesReturn[] = []
  for (const [_, item] of Object.entries(smarkIoVariables)) {
    smarkIoVariablesKeyValue[item.name] = item.value
  }

  return smarkIoVariablesKeyValue
}

export { SmarkioGetVariables }
