import api from '@/services/api'
import { signIn } from 'next-auth/client'
import Link from 'next/link'
import { Input, Form, DatePicker, Checkbox } from 'antd'
import {
  StyledSpace,
  StyledButton,
  StyledFormItem,
  StyledLinkAccount,
  StyledText,
  StyledSpaceAgreeTerms,
} from './styles'
import { MapsPlacesAutocomplete } from '@/components/shared/MapsPlacesAutocomplete'
import { useEffect, useState } from 'react'
import { CustomerProps } from '@/Props/CustomerProps'
import googleMapsAutocompleteParser from '@/utils/googleMapsAutocompleteParser'
import validateMessages from '@/utils/validateMessages'
import moment from 'moment'
import MaskedInput from 'antd-mask-input'
import { AutoDestroyModal } from '@/components/shared/AutoDestroyModal'
import { SmarkioGetVariables } from '@/helpers/SmarkioGetVariables'
import { useClient } from '@/contexts/ClientContext'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/nextjs'
import Text from 'antd/lib/typography/Text'
import { InfoCircleOutlined } from '@ant-design/icons'

type CreateAccountProps = {
  name: string
  email: string
  password: string
  newsletter?: boolean
  phone_number?: any
}

interface CreateAccountFormProps {
  handleTabsCliced: (key: string) => void
}

const CreateAccountForm = ({ handleTabsCliced }: CreateAccountFormProps) => {
  const [form] = Form.useForm()
  const {
    changeAddressName,
    changeClientAddress,
    changeClientCoordinates,
    changeClientCity,
    changeClientDistrict,
    changeClientCountry,
    changeClientPostalCode,
    changeClientAddressId,
    changeIsLoading,
    changeDeliveryZoneError,
    changeAddressError,
    changeClientFromIslands,
  } = useClient()
  const [customerCity, setCustomerCity] = useState('')
  const [customerDistrict, setCustomerDistrict] = useState('')
  const [customerCountry, setCustomerCountry] = useState('')
  const [customerPostalCode, setCustomerPostalCode] = useState('')
  const [customerAddress, setCustomerAddress] = useState('')
  const [customerLatitude, setCustomerLatitude] = useState('')
  const [customerLongitude, setCustomerLongitude] = useState('')
  const [hasSelectedAddress, setHasSelectedAddress] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const handleChange = (address: string) => {
    setHasSelectedAddress(false)
    setCustomerAddress(address)
  }
  const [invalidNumber, setInvalidNumber] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleSelect = async (address: string, placeId: string) => {
    //console.log(suggestion)
    const response = await googleMapsAutocompleteParser({ placeId: placeId })
    if (response.postal_code && response.postal_code.length > 0) {
      // const splitedPostalCode = response.postal_code.split('-')
      // if (
      //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) < 9000
      // ) {
      setHasSelectedAddress(true)
      if (response.city && response.street) {
        setCustomerAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        setCustomerAddress(response.street)
      } else if (response.city) {
        setCustomerAddress(response.city)
      } else {
        setCustomerAddress(response.formatted_address.split(',')[0])
      }
      setCustomerCity(response.city)
      setCustomerDistrict(response.district)
      setCustomerPostalCode(response.postal_code)
      setCustomerCountry(response.country)
      setCustomerLatitude(response.lat.toString())
      setCustomerLongitude(response.lng.toString())

      changeIsLoading(true)
      changeAddressName('')
      if (response.city && response.street) {
        changeClientAddress(`${response.street}, ${response.city}`)
      } else if (response.street) {
        changeClientAddress(response.street)
      } else if (response.city) {
        changeClientAddress(response.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      changeClientCity(response.city)
      changeClientDistrict(response.district)
      changeClientCountry(response.country)
      changeClientPostalCode(response.postal_code)
      changeClientAddressId(placeId)
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeIsLoading(false)
      changeDeliveryZoneError(false)
      // } else {
      //   changeDeliveryZoneError(true)
      // }
    } else {
      const responseMaps = await googleMapsAutocompleteParser({
        lat: response.lat.toString(),
        lng: response.lng.toString(),
      })
      // if (response.postal_code && response.postal_code.length > 0) {
      //   // const splitedPostalCode = responseMaps.postal_code.split('-')
      //   // if (
      //   //   Number(splitedPostalCode.length == 2 ? splitedPostalCode[0] : response.postal_code) < 9000
      //   // ) {
      //   setHasSelectedAddress(true)
      //   if (responseMaps.city && responseMaps.street) {
      //     setCustomerAddress(`${responseMaps.street}, ${responseMaps.city}`)
      //   } else if (responseMaps.street) {
      //     setCustomerAddress(responseMaps.street)
      //   } else if (responseMaps.city) {
      //     setCustomerAddress(responseMaps.city)
      //   } else {
      //     setCustomerAddress(response.formatted_address.split(',')[0])
      //   }
      //   setCustomerCity(responseMaps.city)
      //   setCustomerDistrict(responseMaps.district)
      //   setCustomerPostalCode(responseMaps.postal_code)
      //   setCustomerCountry(responseMaps.country)
      //   setCustomerLatitude(response.lat.toString())
      //   setCustomerLongitude(response.lng.toString())

      //   changeIsLoading(true)
      //   changeAddressName('')
      //   if (responseMaps.city && responseMaps.street) {
      //     changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      //   } else if (responseMaps.street) {
      //     changeClientAddress(responseMaps.street)
      //   } else if (responseMaps.city) {
      //     changeClientAddress(responseMaps.city)
      //   } else {
      //     changeClientAddress(response.formatted_address.split(',')[0])
      //   }
      //   changeClientCoordinates({
      //     latitude: response.lat.toString(),
      //     longitude: response.lng.toString(),
      //   })
      //   changeClientCity(responseMaps.city)
      //   changeClientDistrict(responseMaps.district)
      //   changeClientCountry(responseMaps.country)
      //   changeClientPostalCode(responseMaps.postal_code)
      //   changeClientAddressId(placeId)
      //   window.fbq('track', 'SubmitApplication', {
      //     content_name: 'selected_address',
      //   })
      //   changeDeliveryZoneError(false)
      //   changeIsLoading(false)
      //   // } else {
      //   //   changeDeliveryZoneError(true)
      //   // }
      // }
      // if (
      //   responseMaps.district != 'Madeira' &&
      //   responseMaps.district != 'Açores' &&
      //   responseMaps.district != 'Azores'
      // )
      // else {
      setHasSelectedAddress(true)
      if (responseMaps.city && responseMaps.street) {
        setCustomerAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        setCustomerAddress(responseMaps.street)
      } else if (responseMaps.city) {
        setCustomerAddress(responseMaps.city)
      } else {
        setCustomerAddress(response.formatted_address.split(',')[0])
      }
      setCustomerCity(responseMaps.city)
      setCustomerDistrict(responseMaps.district)
      setCustomerPostalCode(responseMaps.postal_code)
      setCustomerCountry(responseMaps.country)
      setCustomerLatitude(response.lat.toString())
      setCustomerLongitude(response.lng.toString())

      changeIsLoading(true)
      changeAddressName('')
      if (responseMaps.city && responseMaps.street) {
        changeClientAddress(`${responseMaps.street}, ${responseMaps.city}`)
      } else if (responseMaps.street) {
        changeClientAddress(responseMaps.street)
      } else if (responseMaps.city) {
        changeClientAddress(responseMaps.city)
      } else {
        changeClientAddress(response.formatted_address.split(',')[0])
      }
      changeClientCoordinates({
        latitude: response.lat.toString(),
        longitude: response.lng.toString(),
      })
      changeClientCity(responseMaps.city)
      changeClientDistrict(responseMaps.district)
      changeClientCountry(responseMaps.country)
      changeClientPostalCode(responseMaps.postal_code)
      changeClientAddressId(placeId)
      window.fbq('track', 'SubmitApplication', {
        content_name: 'selected_address',
      })
      changeDeliveryZoneError(false)
      changeIsLoading(false)
    }
    // else {
    //   changeDeliveryZoneError(true)
    // }
    // }
  }

  useEffect(() => {
    if (customerDistrict && customerDistrict != '') {
      if (customerDistrict == 'Madeira') {
        changeClientFromIslands('madeira')
      } else if (customerDistrict == 'Açores' || customerDistrict == 'Azores') {
        changeClientFromIslands('açores')
      } else {
        changeClientFromIslands('')
      }
    }
  }, [customerDistrict])

  const onSubmit = async (data: CreateAccountProps) => {
    setSubmitted(true)
    const splitedNumber = data.phone_number.split(' ')
    const phone = Number(splitedNumber.join(''))
    if (isNaN(phone) || data.phone_number.length < 11) {
      setInvalidNumber(true)
    } else {
      setInvalidNumber(false)
      try {
        data.email = data.email.toLowerCase()
        setIsButtonLoading(true)
        if (!hasSelectedAddress) {
          AutoDestroyModal({
            type: 'error',
            title: 'Precisa selecionar uma das moradas sugeridas',
          })
          //alert('Precisa selecionar uma morada das sugeridas')
          setIsButtonLoading(false)
          return
        }
        const smarkIoVariablesKeyValue = SmarkioGetVariables()
        Cookies.set('logout', '5')
        Cookies.set('clientEmail', data.email, { expires: 60 })
        Cookies.set('clientName', data.name, { expires: 60 })
        await api.post<CustomerProps>('/customers/register', {
          ...data,
          address: {
            address: customerAddress,
            city: customerCity,
            country: customerCountry,
            postalCode: customerPostalCode,
            district: customerDistrict,
            is_default: true,
            latitude: customerLatitude,
            longitude: customerLongitude,
          },
          ...smarkIoVariablesKeyValue,
        })

        setIsButtonLoading(false)

        AutoDestroyModal({
          type: 'success',
          title: 'Confirmação conta necessária',
          content: 'Foi enviado um email com o link para ativares a tua conta',
        })

        window.fbq('track', 'CompleteRegistration', {
          content_name: 'create_account',
        })

        if (data.newsletter) {
          window.fbq('track', 'Subscribe', {
            content_name: 'create_account',
          })
        }

        handleTabsCliced('1')

        //const response = await login(data.email, data.password)

        //signIn('credentials', { email: data.email, password: data.password })

        /* if (!response.error) {
        setIsAuthenticated(true)
      } */
      } catch (error) {
        setIsButtonLoading(false)
        if (
          error.response.data.data &&
          error.response.data.data == 'Already exist customer with same email'
        ) {
          AutoDestroyModal({
            type: 'error',
            title: 'Não foi possível criar conta',
            content: 'Já existe cliente registado com o email inserido',
          })
        } else {
          AutoDestroyModal({
            type: 'error',
            title: 'Não foi possível criar conta',
            content: 'Se o problema persisitir contacte o suporte',
          })
        }
        Sentry.captureException(error)

        return
        //console.log(error)
      }
    }
  }

  return (
    <StyledSpace direction="vertical" size={0}>
      <Form
        layout="vertical"
        size="large"
        method="POST"
        onFinish={onSubmit}
        form={form}
        requiredMark={false}
        validateMessages={validateMessages}
      >
        <StyledFormItem label="Nome e apelido" name="first_name" rules={[{ required: true }]}>
          <Input placeholder="Nome e apelido" type="text" />
        </StyledFormItem>
        <StyledFormItem
          label="Endereço de email"
          name="email"
          rules={[
            { required: true },
            { type: 'email', message: 'O email inserido não é um email válido' },
          ]}
        >
          <Input placeholder="Email" type="email" />
        </StyledFormItem>
        <StyledFormItem label="Palavra-passe" name="password" rules={[{ required: true }]}>
          <Input placeholder="Palavra-passe" type="password" />
        </StyledFormItem>
        <StyledFormItem label="Morada">
          <MapsPlacesAutocomplete
            key={0}
            address={customerAddress}
            handleChange={handleChange}
            handleSelect={handleSelect}
            showIcon={false}
            isAddressDeleted={false}
          />
        </StyledFormItem>
        <StyledFormItem label="Apartamento/Andar(opcional)" name="address-second">
          <Input placeholder="Apartamento/Andar" type="text" />
        </StyledFormItem>
        <StyledFormItem label="Número de telefone" name="phone_number" rules={[{ required: true }]}>
          <MaskedInput
            mask="111 111 111"
            placeholder="Número de telefone"
            onChange={(e) => {
              if (submitted && e.target.value.length > 0) {
                const splitedNumber = e.target.value.split(' ')
                const phone = Number(splitedNumber.join(''))
                if (isNaN(phone) || e.target.value.length < 11) {
                  setInvalidNumber(true)
                } else {
                  setInvalidNumber(false)
                }
              }
            }}
          />
        </StyledFormItem>
        {invalidNumber && (
          <div
            style={{
              marginTop: -5,
              marginBottom: 15,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <InfoCircleOutlined
              color="#F16749"
              style={{ color: '#F16749', marginRight: 6, marginTop: 2 }}
            />
            <Text
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: 14,
                color: '#F16749',
              }}
            >
              Número inválido
            </Text>
          </div>
        )}
        <StyledFormItem
          label="Data de nascimento"
          name="birth_date"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const eightYearsAgo = moment().subtract('years', 18)
                const birthday = moment(value)
                if (!birthday.isValid()) {
                  return Promise.reject()
                } else if (eightYearsAgo.isAfter(birthday)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject('A idade tem que ser maior que 18 anos')
                }
              },
            }),
          ]}
        >
          <DatePicker size="large" placeholder="dd/mm/aaaa" format="L" />
        </StyledFormItem>
        <StyledFormItem name="newsletter" valuePropName="checked">
          <Checkbox>
            Recebe novidades e descontos com a subscrição da nossa newsletter. Recebe já uma Edição
            do Mobile Cocktail Book.
          </Checkbox>
        </StyledFormItem>
        <StyledFormItem
          name="termsConditions"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Campo obrigatório')),
            },
          ]}
        >
          <Checkbox>
            Li e aceito os
            <Link href="/termos-e-condicoes">
              <a> termos e condições </a>
            </Link>
            e
            <Link href="/politica-de-privacidade">
              <a> políticas de privacidade</a>
            </Link>
          </Checkbox>
        </StyledFormItem>
        <Form.Item>
          <StyledButton type="primary" size="middle" htmlType="submit" loading={isButtonLoading}>
            {isButtonLoading ? 'A criar a sua conta ...' : 'Criar conta'}
          </StyledButton>
        </Form.Item>
      </Form>
      <StyledSpaceAgreeTerms align="center" direction="vertical" size={0}>
        <StyledText>Ao criar uma conta, confirmo que li e aceito os</StyledText>
        <StyledText>
          <Link href="/termos-e-condicoes">
            <StyledLinkAccount> Termos e condições </StyledLinkAccount>
          </Link>
          <Link href="/politica-de-privacidade">
            <StyledLinkAccount> Política de privacidade </StyledLinkAccount>
          </Link>
        </StyledText>
      </StyledSpaceAgreeTerms>
      <StyledText>
        Já tens conta?
        <StyledLinkAccount onClick={() => handleTabsCliced('1')}>
          Inicia sessão aqui
        </StyledLinkAccount>
      </StyledText>
    </StyledSpace>
  )
}

export default CreateAccountForm
