import LoginForm from '../CustomerAccountLayout/AccountLogin'
import CreateAccountForm from '../CustomerAccountLayout/CreateAccount'
import { Tabs } from 'antd'
import { StyledTabs } from './styles'
import { useState } from 'react'
const { TabPane } = Tabs

const SignInCreateAccount = () => {
  const [activeTab, setActiveTab] = useState('1')

  const handleTabsCliced = (key: string) => {
    window.scrollTo(0, 0)
    setActiveTab(key)
  }

  return (
    <StyledTabs defaultActiveKey="1" activeKey={activeTab} onTabClick={handleTabsCliced}>
      <TabPane tab="Iniciar sessão" key="1">
        <LoginForm handleTabsCliced={handleTabsCliced} />
      </TabPane>
      <TabPane tab="Criar conta" key="2">
        <CreateAccountForm handleTabsCliced={handleTabsCliced} />
      </TabPane>
    </StyledTabs>
  )
}

export { SignInCreateAccount }
